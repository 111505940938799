export const types = {
  loadconfig: "load config",
  login: "login",
  logout: "logout",
  setPages: "setPages",
  addNewRoute: "addNewRoute",
  UpdateRoute: "UpdateRoute",
  DeleteRoute: "DeleteRoute"
};



export const typesChart = {
  GET_ALL_CLIENT:'GET_ALL_CLIENT',
  GET_RANKING:'GET_RANKING',
  GET_CONTENIDO_EVOLUTIVO_RESUMEN:'GET_CONTENIDO_EVOLUTIVO_RESUMEN',
  GET_CHART_EVOLUTIVO:'GET_CHART_EVOLUTIVO',
  GET_CHART_PREGUNTA_EVOLUTIVO:'GET_CHART_PREGUNTA_EVOLUTIVO',
  GET_DATA_TABLE:'GET_DATA_TABLE',
  GET_LOBS:'GET_LOBS',
  GET_PREGUNTA_ATRIBUTO_MODULO:'GET_PREGUNTA_ATRIBUTO_MODULO',
  GET_FILCAL_YEARS:'GET_FILCAL_YEARS',
  GET_CASINOS:'  GET_CASINOS:',
  GET_CODIGOMEDICION:'GET_CODIGOMEDICION',
  GET_BYI:'GET_BYI',
  GET_LINEA_NEGOCIO:'GET_LINEA_NEGOCIO',
  GET_PLATOS_RANKING:'GET_PLATOS_RANKING',
  GET_ALL_PLATOS_RANKING:'GET_ALL_PLATOS_RANKING',
  BUSCANDO_API:'BUSCANDO_API',
  GET_ULTIMAS_MEDICIONES:'GET_ULTIMAS_MEDICIONES'
}


export const typesUser = {
  CREAR_USUARIO:'CREAR_USUARIO',
  GET_USERS:'GET_USERS',
  ALERT_EDIT_REGISTER:'SUCESS_EDIT_REGISTER',
  DELETE_ALERT:'DELETE_ALERT'

}
export const typesAlertas = {

  MOSTRAR_ALERTA:'MOSTRAR_ALERTA',
  OCULTAR_ALERTA:'OCULTAR_ALERTA'

}

