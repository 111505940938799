import React, { useEffect, useState,useContext } from "react";
import { withRouter, Switch, Route, Redirect ,useHistory } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { GlobalConfigContext } from '../../context/global/GlobalConfigContext';

import './Header.scss';
import './HeaderMenuLinks.scss';

const Header = () => {
    const navigate = useHistory();

    //GlobalConfig
    const { globalConfig } = useContext(GlobalConfigContext);  
    const { pageTitle }  = globalConfig;

    const [state, setState] = useState({
        pageTitle: '',
        navIcon: 'menu-link-slide',
        dropdownOpen: false
    });

    useEffect(() => {        
        // let pubsub_token = pubsub.subscribe('setPageTitle', (ev, title) => {
        //    setState({pageTitle: title});
        // });
        // let pubsub_token_icon = pubsub.subscribe('setNavIcon', (ev, icon) => {
        //    setState({navIcon: icon});
        // });

        // pubsub.unsubscribe(pubsub_token);
        // pubsub.unsubscribe(pubsub_token_icon);

        // return () => {
        //     cleanup
        // }
    }, []);

    const toggle = () => {
        setState(prevState => ({
          dropdownOpen: !prevState.dropdownOpen
        }));
    }

    const logout = () =>{
        localStorage.clear();
        return (<Redirect to={'/login'}/>)
    }
    const perfil = () =>{
        navigate.push('/Perfil')
    }

    return (
        <header className="header-container">
            <nav>                
                <ul className="d-lg-block">
                    <li>
                        {/* <a id="show-sidebar" className={"menu-link "+ state.navIcon}> */}
                        <a id="show-sidebar" className={"menu-link menu-link-slide"}>
                            <span><em></em></span>
                        </a>
                    </li>
                </ul>
                {/* <h2 className="header-title">{state.pageTitle}</h2> */}
                <h2 className="header-title">{pageTitle}</h2>
                <ul className="float-right">
                    <Dropdown id="basic-nav-dropdown" tag="li" isOpen={state.dropdownOpen} toggle={toggle}>
                        <DropdownToggle nav className="has-badge ripple" >
                          {/* <em className="ion-person"></em> */}
                          <em className="ion-gear-b"></em>
                        </DropdownToggle>


                        <DropdownMenu right className="md-dropdown-menu" >
                                <DropdownItem onClick={perfil}>
                                    <em className="ion-person icon-fw"></em>
                                    Contraseña
                                </DropdownItem >

                        <a href="#"  onClick={logout}>
                            {/* <a href="#"  onClick={logout.bind(this)}> */}
                            {/* <LinkContainer onClick={this.logout.bind(this)}> */}
                                <DropdownItem>
                                    <em className="ion-log-out icon-fw"></em>
                                    Salir
                                </DropdownItem >
                            </a>


                        </DropdownMenu>

                    </Dropdown>
                </ul>

            </nav>
        </header>
    );

}

export default Header;
