import React, {useContext, useEffect, useState, Suspense, lazy } from 'react';


// const MantenedorUsuarios = lazy(() => import('./views/Usuarios/MantenedorUsuarios'));
// const MantenedorRoles = lazy(() => import('./views/Roles/MantenedorRoles'));
// const DownloadFiles = lazy(() => import('./views/Download/DownloadFiles'));


// const Home = lazy(() => import('./views/Home/Home'));
const Home = lazy(() => import('./views/Home/Home'))
const Graficos = lazy(() => import('./views/Graficos/Graficos'));
const PageNotFound = lazy(() => import('./views/Errors/PageNotFound'));
const MantenedorUsuarios = lazy(() => import('./views/Usuarios/MantenedorUsuarios'));
const MantenedorRoles = lazy(() => import('./views/Roles/MantenedorRoles'));
const MantenedorMenuIzquierdo = lazy(() => import('./views/MantenedorMenuIzquierdo/MantenedorMenuIzquierdo'));
const MantenedorAgrupaciones = lazy(() => import('./views/MantenedorAgrupaciones/MantenedorAgrupaciones'));

let ComponentMap =  
    {
        "Home": Home,
        "MantenedorUsuarios": MantenedorUsuarios,
        "MantenedorRoles": MantenedorRoles,
        "MantenedorMenuIzquierdo":MantenedorMenuIzquierdo,
        "MantenedorAgrupaciones":MantenedorAgrupaciones,         
        "default": PageNotFound,
    }


// let ComponentMap =  
// [
//     {
//         "Name":"Home",
//         "Home": Home, 
//         "Params":false
//     }, 
//     {
//         "Name":"DynamicChart",
//         "DynamicChart": DynamicChart, 
//         "Params":true
//     }, 
//     {
//         "Name": "MantenedorUsuarios",
//         "MantenedorUsuarios": MantenedorUsuarios, 
//         "Params":false
//     }, 
//     {
//         "Name": "MantenedorRoles",
//         "MantenedorRoles": MantenedorRoles, 
//         "Params":false
//     },
//     {
//         "Name":"PageNotFound",
//         "default": PageNotFound, 
//         "Params":false
//     }
// ]
    


export default ComponentMap;
