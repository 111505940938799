export const configSidebarAdmin = [
	{
		name: 'Inicio',
		path: '/home',
		icon: 'fas fa-home',
		label: {
			value: 1,
			classNme: 'bg-success'
		}
	},
	{
		name: 'NPS',
		icon: 'fas fa-chart-line',

		submenu: [
			{
				icon: 'far fa-chart-bar',
				name: 'Generales',
				path: '/evolutivo-resumen',
				label: {
					value: 2,
					classNme: 'bg-success'
				}
			},
			{
				icon: 'far fa-chart-bar',
				name: 'Almuerzo',
				path: '/evolutivo-almuerzo',
				label: {
					value: 2,
					classNme: 'bg-success'
				}
			},
			{
				icon: 'far fa-chart-bar',
				name: 'Desayuno',
				path: '/evolutivo-desayuno',
				label: {
					value: 2,
					classNme: 'bg-success'
				}
			},
			{
				icon: 'far fa-chart-bar',
				name: 'Cena',
				path: '/evolutivo-cena',
				label: {
					value: 2,
					classNme: 'bg-success'
				}
			},
			// {
			// 	icon: 'far fa-chart-bar',
			// 	name: 'Hotelería',
			// 	path: '/evolutivo-hoteleria',
			// 	label: {
			// 		value: 2,
			// 		classNme: 'bg-success'
			// 	}
			// },
			// {
			// 	icon: 'far fa-chart-bar',
			// 	name: 'Recreación',
			// 	path: '/evolutivo-recreacion',
			// 	label: {
			// 		value: 2,
			// 		classNme: 'bg-success'
			// 	}
			// },
			// {
			// 	icon: 'far fa-chart-bar',
			// 	name: 'Aseo',
			// 	path: '/evolutivo-aseo',
			// 	label: {
			// 		value: 2,
			// 		classNme: 'bg-success'
			// 	}
			// },
			// {
			// 	icon: 'far fa-chart-bar',
			// 	name: 'Platos preferidos',
			// 	path: '/evolutivo-platosPreferidos',
			// 	label: {
			// 		value: 2,
			// 		classNme: 'bg-success'
			// 	}
			// }
		]
	},

];

export const configSidebarEvolutivo = [
	{
		name: 'Inicio',
		path: '/home',
		icon: 'fas fa-home',
		label: {
			value: 1,
			classNme: 'bg-success'
		}
	},
	{
		name: 'Relación Comercial',
		path: '/RelacionComercial',
		icon: 'fas fa-chart-line',
		label: {
			value: 1,
			classNme: 'bg-success'
		}
	},
	{
		name: 'Disponibilidad de locales para arriendo',
		path: '/DisponibilidadLocales',
		icon: 'fas fa-chart-line',
		label: {
			value: 1,
			classNme: 'bg-success'
		}
	},
	{
		name: 'Contratación',
		path: '/ProcesoContratacion',
		icon: 'fas fa-chart-line',
		label: {
			value: 1,
			classNme: 'bg-success'
		}
	},
	{
		name: 'Habilitación de locales',
		path: '/ProcesoDeHabilitacion',
		icon: 'fas fa-chart-line',
		label: {
			value: 1,
			classNme: 'bg-success'
		}
	},
	{
		name: 'Funcionamiento y operación',
		path: '/FuncionamientoOperacion',
		icon: 'fas fa-chart-line',
		label: {
			value: 1,
			classNme: 'bg-success'
		}
	},
	{
		name: 'Marketing y promoción',
		path: '/MarketingPromocion',
		icon: 'fas fa-chart-line',
		label: {
			value: 1,
			classNme: 'bg-success'
		}
	},
	{
		name: 'Facturación y cobranza',
		path: '/ProcesoFacturacion',
		icon: 'fas fa-chart-line',
		label: {
			value: 1,
			classNme: 'bg-success'
		}
	},
	{
		name: 'Tasa de problemas',
		path: '/TasaDeProblemas',
		icon: 'fas fa-chart-line',
		label: {
			value: 1,
			classNme: 'bg-success'
		}
	}
];
export const configSidebarComparativo =  [
	{
		name: 'Inicio',
		path: '/home',
		icon: 'fas fa-home',
		label: {
			value: 1,
			classNme: 'bg-success'
		}
	},
	{
		name: 'NPS',
		icon: 'fas fa-chart-line',

		submenu: [
			{
				icon: 'far fa-chart-bar',
				name: 'Generales',
				path: '/evolutivo-resumen',
				label: {
					value: 2,
					classNme: 'bg-success'
				}
			},
			{
				icon: 'far fa-chart-bar',
				name: 'Almuerzo',
				path: '/evolutivo-almuerzo',
				label: {
					value: 2,
					classNme: 'bg-success'
				}
			},
			{
				icon: 'far fa-chart-bar',
				name: 'Desayuno',
				path: '/evolutivo-desayuno',
				label: {
					value: 2,
					classNme: 'bg-success'
				}
			},
			{
				icon: 'far fa-chart-bar',
				name: 'Cena',
				path: '/evolutivo-cena',
				label: {
					value: 2,
					classNme: 'bg-success'
				}
			},
			// {
			// 	icon: 'far fa-chart-bar',
			// 	name: 'Hotelería',
			// 	path: '/evolutivo-hoteleria',
			// 	label: {
			// 		value: 2,
			// 		classNme: 'bg-success'
			// 	}
			// },
			// {
			// 	icon: 'far fa-chart-bar',
			// 	name: 'Recreación',
			// 	path: '/evolutivo-recreacion',
			// 	label: {
			// 		value: 2,
			// 		classNme: 'bg-success'
			// 	}
			// },
			// {
			// 	icon: 'far fa-chart-bar',
			// 	name: 'Aseo',
			// 	path: '/evolutivo-aseo',
			// 	label: {
			// 		value: 2,
			// 		classNme: 'bg-success'
			// 	}
			// },
			// {
			// 	icon: 'far fa-chart-bar',
			// 	name: 'Platos preferidos',
			// 	path: '/evolutivo-platosPreferidos',
			// 	label: {
			// 		value: 2,
			// 		classNme: 'bg-success'
			// 	}
			// }
		]
	},
	{
		name: 'Locales',
		path: '/ranking',
		icon: 'fas fa-chart-line',
		label: {
			value: 1,
			classNme: 'bg-success'
		}
	},
	{
		name: 'Contratación',
		path: '/home',
		icon: 'fas fa-chart-line',
		label: {
			value: 1,
			classNme: 'bg-success'
		}
	},
	{
		name: 'Habilitación',
		path: '/home',
		icon: 'fas fa-chart-line',
		label: {
			value: 1,
			classNme: 'bg-success'
		}
	},
	{
		name: 'Func./Operación',
		path: '/home',
		icon: 'fas fa-chart-line',
		label: {
			value: 1,
			classNme: 'bg-success'
		}
	},
	{
		name: 'Marketing',
		path: '/home',
		icon: 'fas fa-chart-line',
		label: {
			value: 1,
			classNme: 'bg-success'
		}
	},
	{
		name: 'Facturación/Cobranza',
		path: '/home',
		icon: 'fas fa-chart-line',
		label: {
			value: 1,
			classNme: 'bg-success'
		}
	},
	{
		name: 'Reacción Comercial',
		path: '/home',
		icon: 'fas fa-chart-line',
		label: {
			value: 1,
			classNme: 'bg-success'
		}
	},
	{
		name: 'Configuración',
		path: '/home',
		icon: 'fas fa-cogs',
		label: {
			value: 1,
			classNme: 'bg-success'
		}
	},
	{
		name: 'Tasa de problemas',
		path: '/TasaDeProblemas',
		icon: 'fas fa-cogs',
		label: {
			value: 1,
			classNme: 'bg-success'
		}
	}
];
