import React from 'react'
import SingleItemLabel from './SingleItemLabel.jsx'
import SingleItemIcon from './SingleItemIcon.jsx'
import SingleItem from  './SingleItem.jsx'   

 const SubMenuItem = ({ item, routeActive }) => [
 
         <a href={`#${item.name}`} className="ripple" key="0">
      <span className="float-right nav-caret">
         <em className="ion-ios-arrow-right" />
      </span>
      {item.label && <SingleItemLabel label={item.label} />}
      {item.icon && <SingleItemIcon src={item.icon} />}
      <span>{item.name}</span>
   </a>,
   <ul className="sidebar-subnav" key="1">
      {item.submenu.map((sitem, si) => (
         <li className={routeActive(sitem.path)} key={si}>
         <SingleItem item={sitem} />
         {/*JSON.stringify(sitem,3, null)*/}
         </li>
      ))}
   </ul>
   
 ]
export default SubMenuItem;