import React from 'react'
import { SVGReplace } from "../Utils/Utils";

const SingleItemIcon = ({src}) => {
   return (
      
      <i className={`${src} mr-1`}></i>
     
   )
}
export default SingleItemIcon;

