import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import pubsub from "pubsub-js";
import { withNamespaces, Trans } from "react-i18next";

import "./Sidebar.scss";
import SidebarRun from "./Sidebar.run";
import { SVGReplace } from "../Utils/Utils";
import Menu from "../../Menu.js";

import { GlobalConfigContext } from "../../context/global/GlobalConfigContext";
import { typeGlobalConfig } from "../../types/typeGlobalConfig";
import axios from "axios";
import SingleItemLabel from './SingleItemLabel.jsx'
import SingleItemIcon from './SingleItemIcon.jsx'
import SingleItem from './SingleItem.jsx'
import SubMenuItem from './SubMenuItem.jsx'
const config = require("../../configuration");
const API_BASE_URL = config.API_BASE_URL;
const arrayPaginas = JSON.parse(localStorage.getItem("arrayPaginas")) || [];
const ROL = localStorage.getItem("rol");
const username = localStorage.getItem("usuario");

// console.log(nombre_user)

// Todo cambiar a un componente mas pequeño
// const SingleItemLabel = ({ label }) => (
//   <span className="float-right nav-label">
//     {/* <span className={"badge " + label.className}>{label.value}</span> */}
//     <span className={"badge " + label.className}></span>
//   </span>
// );
// Todo cambiar a un componente mas pequeño
// const SingleItemIcon = ({ src }) => (
//   <span className="nav-icon">
//     <SVGReplace src={src} className="invisible" />
//   </span>
// );
// Todo cambiar a un componente mas pequeño
// const SingleItem = ({ item }) => (
//   // let tituloPagina: item.name ? item.name : 'Home';

//   // <Link to={item.path} className="ripple">
//   // <Link to={item.path} className="ripple" titulo = { (item.name && item.name != '') ? item.name : 'Home'}>
//   <Link
//     to={item.path}
//     className="ripple"
//     titulo={item.name && item.name != "" ? item.name : "Home"}
//   >
//     {item.label && <SingleItemLabel label={item.label} />}
//     {item.icon && <SingleItemIcon src={item.icon} />}
//     <span>{item.name}</span>
//   </Link>
// );
// Todo cambiar a un componente mas pequeño
// const SubMenuItem = ({ item, routeActive }) => [
//   <a href={`#${item.name}`} className="ripple" key="0">
//     <span className="float-right nav-caret">
//       <em className="ion-ios-arrow-right" />
//     </span>
//     {item.label && <SingleItemLabel label={item.label} />}
//     {item.icon && <SingleItemIcon src={item.icon} />}
//     <span>{item.name}</span>
//   </a>,
//   <ul className="sidebar-subnav" key="1">
//     {item.submenu.map((sitem, si) => (
//       <li className={routeActive(sitem.path)} key={si}>
//         <SingleItem item={sitem} />
//         {/*JSON.stringify(sitem,3, null)*/}
//       </li>
//     ))}
//   </ul>,
// ];

const Sidebar = (props) => {
  // const { globalConfig } = useContext(GlobalConfigContext);
  const { globalConfig, dispatchGlobalConfig } = useContext(
    GlobalConfigContext
  );

  const { configSidebar } = globalConfig;
  const [sidebarModes, setSidebarModes] = useState({
    header: true,
    toolbar: true,
    offcanvas: false,
  });

  // console.log(globalConfig);
  const [stateClassSidebar, setStateClassSidebar] = useState(
    "sidebar-container"
  );
  // const [stateMenu, setStateMenu] = useState([]);
  // const [stateMenu, setStateMenu] = useState([
  //     {
  //         name: 'Home',
  //         path: '/home',
  //         icon: 'img/icons/home.svg',
  //         label: { value: 1, classNme: 'bg-success' }
  //     },
  //     {
  //         name: 'Mantenedores',
  //         icon: 'img/icons/user-check.svg',
  //         submenu: [
  //             {
  //                 name: 'Usuarios',
  //                 path: '/mantenedorusuarios'
  //             },
  //             {
  //                 name: 'Roles',
  //                 path: '/mantenedorroles'
  //             }

  //     ]},
  // ]
  // );

  const nombre_user = localStorage.getItem("nombre")
  console.log(nombre_user)
  useEffect(() => {
    // console.log("globalConfig", globalConfig);
    // console.log("configSidebar", configSidebar);
    // getJerarquia();
    
    SidebarRun(dispatchGlobalConfig);
    let pubsub_token = pubsub.subscribe("sidebarmode", (msg, mode) => {
      // this.setState({
      //     sidebarModes: {
      //         ...this.state.sidebarModes,
      //         [mode]: !this.state.sidebarModes[mode]
      //     }
      // });
      setSidebarModes({
        ...sidebarModes,
        [mode]: !this.state.sidebarModes[mode],
      });
      // if (mode === "offcanvas") {
      //     document.body.classList[
      //         this.state.sidebarModes["offcanvas"] ? "add" : "remove"
      //     ]("sidebar-offcanvas");
      // }
      if (mode === "offcanvas") {
        document.body.classList[sidebarModes["offcanvas"] ? "add" : "remove"](
          "sidebar-offcanvas"
        );
      }
    });
    // Listen for routes changes in order to hide the sidebar on mobile
    props.history.listen(() => {
      document
        .querySelector(".layout-container")
        .classList.remove("sidebar-visible");
    });
    pubsub.unsubscribe(pubsub_token);
  }, []);

  const hasSubmenu = (item) => item.submenu && item.submenu.length;

  const routeActive = (paths) => {
    paths = Array.isArray(paths) ? paths : [paths];
    return paths.some((p) => props.location.pathname.indexOf(p) > -1)
      ? "active"
      : "";
  };



 
  return (
    // <aside className={stateClassSidebar}>
    <aside className="sidebar-container">
      {sidebarModes.header && (
        <div className="sidebar-header">
          {/* <div id="hide-sidebar" className="sidebar-header-logo" onClick={()=> handlerChangeVisibilityFalse}> */}
          <div className="row">
            <div id="hide-sidebar" className="sidebar-header-logo">
              <a className="menu-link menu-link-slide back-btn">
                <span>
                  <em></em>
                </span>
              </a>
            </div>
            <div className="col-md-8 mt-3 mr-1">
              <img
                src="img/mallplazaLogo.png"
                alt="Logo"
                className="img-fluid wd-xl"
              />
            </div>
          </div>
        </div>
      )}
      <div className="sidebar-content">
        {sidebarModes.toolbar && (
          <div className="sidebar-toolbar text-center">
            <a href="#user">
              <img
                src="img/user/08.jpg"
                alt="Profile"
                className="rounded-circle thumb64"
              />
            </a>
            <div className="mt">
              <span className="span-user">
                { nombre_user }
              </span>
            </div>
          </div>
        )}
        <nav className="sidebar-nav mt">

        
          <ul>
            {/* {stateMenu.map((item, i) => { */}
            {configSidebar.map((item, i) => {
              const routes = hasSubmenu(item)
                ? item.submenu.map((i) => i.path)
                : [item.path];
              return (
                <li className={routeActive(routes)} key={i}>
                  {hasSubmenu(item) ? (
                    <SubMenuItem item={item} routeActive={routeActive} />
                  ) : (
                    <SingleItem
                      item={item}
                      setStateClassSidebar={setStateClassSidebar}
                    />
                  )}
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default withNamespaces("translations")(withRouter(Sidebar));
