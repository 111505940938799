import axios from 'axios';
import Swal from 'sweetalert2';
// import { Redirect } from 'react-router-dom';

// const token = localStorage.getItem("userToken");
// console.log('INTERCEPTOR TOKEN', token);
// const expiracion = localStorage.getItem("expiracion");


axios.interceptors.request.use(function (config) {  
  const token = localStorage.getItem("userToken");

  console.log('INTERCEPTOR TOKEN', token);

    if (token) {
        // Verificar si el token está expirado
        const decodedToken = decodeJWT(token);
        const currentTime = Date.now() / 1000;

        if (decodedToken.exp < currentTime) {
            // Si el token ha expirado, redirigir al usuario a la página de inicio de sesión
            localStorage.clear();
            window.location.href = '/login';
        } else {
            // Si el token no ha expirado, agregarlo al encabezado de autorización
            config.headers.Authorization = `Bearer ${token}`;
        }
    }else {
        if(config.url.includes('login')){

        }else {
            localStorage.clear();
            window.location.href = '/login';
        }
    }

    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log('config interceptors response:' , response);

    return response;
  }, function (error) {
    if(error.response.data.errors !== undefined){
        if(error.response.data.errors.expiredAt !== undefined)
        {        
            var valHtml = document.createElement("p");
            valHtml.setAttribute('class', 'col-xs-12');  
            valHtml.setAttribute('style', 'font-size:16px'); 
            localStorage.clear();        
            var texto = document.createTextNode('Su sesión ha expirado');
            valHtml.appendChild(texto);
            Swal.fire({
            title: 'Advertencia',
            icon: 'warning',
            html: valHtml,                                   
        }).then((result) => {
            window.location.href = '/login';

        })
      }
                          
    //   Swal.fire({
    //     title: 'Advertencia',
    //     icon: 'success',
    //     html: valDivHtml,                           
    //     showCancelButton: false,
    //     // customClass: 'sweetalert-lg',
    //     confirmButtonColor: '#3085d6',
    //     cancelButtonColor: '#d33',
    //     confirmButtonText: 'Ok'
    //   }).then((result) => {
    //     if (result.value) {
    //         estadoValidacionFile= false;
    //         return false;
    //     }
    //   })


        // window.location.href = '/login';
    }

    
    return Promise.reject(error);
  });

function decodeJWT(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
}
