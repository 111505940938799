import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';

import './Material.scss';

// import FormValidator from "../Forms/Validator.js";
// import "../Forms/Material.scss";
// import { LoginPost } from '../../services/LoginPost';


//Context
//////////////////////////////////////////////////////////////////////
import { AuthContext } from '../../context/auth/AuthContext.js';
import { types } from '../../types/types.js';
import { GlobalConfigContext } from '../../context/global/GlobalConfigContext';
import { typeGlobalConfig } from '../../types/typeGlobalConfig';
import { configSidebarAdmin, configSidebarComparativo, configSidebarEvolutivo } from '../../data/menu';
//////////////////////////////////////////////////////////////////////

// import { useHistory } from "react-router-dom";

const config = require('../../configuration');
const API_BASE_URL = config.API_BASE_URL;

const Login = ({ history }) => {
	// const Login = () => {
		const [loading, setLoading] = React.useState(false);
		const [success, setSuccess] = React.useState(false);
		const timer = React.useRef();
	const { dispatch } = useContext(AuthContext);
	const { dispatchGlobalConfig } = useContext(GlobalConfigContext);

	const { register, handleSubmit, errors, reset } = useForm();

	const onSubmit = async (data) => {
		// console.log('Data', data);
		servicePostLogin(data.usuario, data.password);
	};

	const [ stateLogin, setStateLogin ] = useState({
		usuario: '',
		password: ''
	});

	const handlerLogin = (e) => {
		setStateLogin({
			...stateLogin,
			[e.target.name]: e.target.value
		});
	};

	React.useEffect(() => {
		return () => {
		  clearTimeout(timer.current);
		};
	  }, []);
	
	const handleButtonClick = () => {
		if (!loading) {
		  setSuccess(false);
		  setLoading(true);
		  timer.current = window.setTimeout(() => {
			setSuccess(true);
			setLoading(false);
		  }, 2000);
		}
	  };

	const servicePostLogin = (username, password) => {
		axios
			.post(API_BASE_URL + '/login', { email: username, password: password })
			.then( (user) => {

				let rol_id = null;
				// console.log(user);
				if (user.status === 200) {
					var vista = '';
					
					rol_id = parseInt(user.data.rol_id);
					/*localStorage.setItem('userToken', user.data.token);
					localStorage.setItem('nombre', user.data.nombre);
					localStorage.setItem('rol', user.data.rol);
					localStorage.setItem('rol_id', user.data.rol_id);
					localStorage.setItem('ccSQL', user.data.ccSQL);
					localStorage.setItem('usuario', user.data.usuario);
					localStorage.setItem('useremail', user.data.email);*/
					localStorage.setItem('userToken', user.data.data.token);
					localStorage.setItem('nombre',user.data.data.nombre);
					localStorage.setItem('rol', user.data.data.rol);
					localStorage.setItem('rol_id', user.data.data.rol);
					localStorage.setItem('ccSQL', user.data.ccSQL);
					localStorage.setItem('usuario', user.data.data.nombre);
					localStorage.setItem('useremail', user.data.data.nombre);

					let paginas = [{"NOMBRE": "home","RUTA": "/home","COMPONENTE": "Home","TIPO": "STATIC"}];
					let arrayPaginas = [];
					// let rol = 'rol-admin';
					
					localStorage.setItem("paginas", JSON.stringify(paginas));
					localStorage.setItem("arrayPaginas",JSON.stringify(arrayPaginas));
					console.log(rol_id)
					console.log("Usuario",user)
					if ( user.data.data.user ) {
						dispatch({
							type: types.login,
							payload: {
								/*usuario: user.data.usuario,
								rol: user.data.rol_id,*/
								usuario: 'User',
								rol:1,
								paginas
							}
						});
						localStorage.removeItem('NoAutorizado');
						switch (user.data.user) {
							case 1:
								getSidebarAdmin();
								break;
							default:
								getSidebarEvolutivo();
								break;
						}
						history.push('/home');
					} else {
						Swal.fire("Oops...", "Usuario no posee un rol asignado!", "warning");
						localStorage.clear();
					}

				} else {
					Swal.fire('Oops...', 'Usuario o password invalido!', 'error');
					localStorage.clear();

				}
			})
			.catch((error) => {
				console.log('Error', error);
				Swal.fire('Oops...', 'Usuario o password invalido!', 'error');
				localStorage.clear();

			})
			.finally(function() {
				console.log('Respuesta Finally');
			});
	};

	//!-------!

	const getSidebar = async () => {
		console.log('Se ejecuto la funcion');

		dispatchGlobalConfig({
			type: typeGlobalConfig.addAllSidebar,
			payload: configSidebarAdmin
		});
		//! Codigo antiguo
		// await axios
		//   .get(`${API_BASE_URL}/site-config-get-global-config-sidebar`)
		//   .then((response) => {
		//     let tos = true
		//     if (tos) {
		//       let sidebar = response.data.sidebar;
		//       console.log("response.data.Sidebar", response.data);
		//       console.log("datosSidebar", sidebar);
		//       console.log(response,'get sidebar');
		//       dispatchGlobalConfig({
		//         type: typeGlobalConfig.addAllSidebar,
		//         payload: sidebar,
		//       });
		//     }
		//   })
		//   .catch((error) => {
		//     console.log(error);
		//   });
	};

	const getSidebarAdmin = async () => {
		dispatchGlobalConfig({
			type: typeGlobalConfig.addAllSidebar,
			payload: configSidebarAdmin
		});
	};
	const getSidebarComparativo = async () => {
		dispatchGlobalConfig({
			type: typeGlobalConfig.addAllSidebar,
			payload: configSidebarComparativo
		});
	};
	const getSidebarEvolutivo = async () => {
		dispatchGlobalConfig({
			type: typeGlobalConfig.addAllSidebar,
			payload: configSidebarEvolutivo
		});
	};

	return (
		<div className="container-full">
			<div className="container container-xs">
				<div className="text-center">
					<img className="mv-lg img-fluid wd-lg" src="img/mallplazaLogo21.png" alt="Logo MALLPLAZA" />
				</div>
				<form
					className="cardbox b0 form-validate"
					autoComplete="off"
					onSubmit={handleSubmit(onSubmit)}
					noValidate
				>
					<div className="cardbox-offset pb0">
						<div className="cardbox-offset-item text-right invisible">
							<div className="btn btn-success btn-circle btn-lg">
								<em className="ion-checkmark-round" />
							</div>
						</div>
					</div>
					<div className="cardbox-heading">
						<div className="cardbox-title text-center">Acceso</div>
					</div>
					<div className="cardbox-body">
						<div className="mda-form-group float-label mda-input-group">
							<div className="mda-form-control">
								<input
									className={errors.usuario ? 'is-invalid form-control' : 'form-control'}
									aria-invalid={errors.usuario ? 'true' : 'false'}
									type="text"
									id="usuario"
									name="usuario"
									autoComplete="off"
									placeholder="usuario"
									ref={register({
										required: '',
										pattern: {
											// value: /\S+@\S+\.\S+/,
											// message: "usuario invalido",
										}
									})}
								/>
								<div className="mda-form-control-line" />
							</div>
							<span className="mda-input-group-addon">
								<em className="ion-ios-email-outline icon-lg" />
							</span>
						</div>
						<div className="mda-form-group float-label mda-input-group">
							<div className="mda-form-control">
								<input
									className={errors.password ? 'is-invalid form-control' : 'form-control'}
									aria-invalid={errors.password ? 'true' : 'false'}
									type="password"
									id="password"
									name="password"
									placeholder="Password"
									// onChange={handlerLogin}
									// value={stateLogin.password}
									ref={register({
										required: ''
										// minLength: {
										//   value: 5,
										//   message: "min length is 5"
										// }
									})}
									// invalid={this.hasError(
									//     "formLogin",
									//     "password",
									//     "required"
									// )}
									// onChange={this.validateOnChange}
									// data-validate='["required"]'
									// value={this.state.formLogin.password}
								/>
								<div className="mda-form-control-line" />
								{/* <label>Password</label> */}
							</div>
							<span className="mda-input-group-addon">
								<em className="ion-ios-locked-outline icon-lg" />
							</span>
						</div>
					</div>
					<div className="col-md-12">
						<div className="form-group">
							<Link
								to="/recover"
								style={{
									font: '#EC1A59',
									fontWeight: 'bold',
									color: '#EC1A59',
									fontSize: '15px',
									textDecoration: 'none'
								}}
							>
								¿Has olvidado tu contraseña?
							</Link>
						</div>
					</div>
					<button
						// className="btn btn-primary btn-flat"
						className="btn text-white"
						style={{backgroundColor:'#EC1A59'}}
						type="submit"
						onClick={handleButtonClick}
					>
						Ingresar
					</button>
					{loading && (
						<CircularProgress
							size={60}
							sx={{
							position: 'absolute',
							top: '120%',
							left: '50%',
							marginTop: '-20px',
							marginLeft: '-12px',
							}}
						/>
						)}
				</form>
			</div>
		</div>
	);
};

export default Login;
