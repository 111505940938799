import React from 'react'

const SingleItemLabel = ({label}) => {
   return (
      <span className="float-right nav-label">
            {/* <span className={"badge " + label.className}>{label.value}</span> */}
         <span className={"badge " + label.className}></span>
      </span>
   )
}

export default SingleItemLabel
