import { typesChart } from '../../types/types';

export default (state, action) => {
	switch (action.type) {
		case typesChart.GET_CONTENIDO_EVOLUTIVO_RESUMEN:
			return {
				...state,
				contenidoEvolutivoResumen: action.payload
			};
		case typesChart.GET_ULTIMAS_MEDICIONES:
			return{
				...state,
				mediciones:action.payload
			}
		case typesChart.GET_CHART_EVOLUTIVO:
			return {
				...state,
				chartEvolutivo: action.payload
			};
		case typesChart.GET_CHART_PREGUNTA_EVOLUTIVO:
			return {
				...state,
				chartPreguntaEvolutivo: action.payload
			};
		case typesChart.GET_DATA_TABLE:
			return {
				...state,
				dataTable: action.payload
			};
		case typesChart.GET_RANKING:
			return {
				...state,
				rankings: action.payload
			};
		case typesChart.GET_PLATOS_RANKING:
			return {
				...state,
				platos: action.payload
			};
		case typesChart.GET_ALL_PLATOS_RANKING:
			return {
				...state,
				all_platos: action.payload
			};
		case typesChart.GET_LOBS:
			localStorage.setItem('lobs', JSON.stringify(action.payload));
			return {
				...state,
				lobs: action.payload
			};
		case typesChart.GET_FILCAL_YEARS:
			localStorage.setItem('filcalYears', JSON.stringify(action.payload));
			return {
				...state,
				filcalYears: action.payload
			};
		case typesChart.GET_ALL_CLIENT:
			localStorage.setItem('clientes', JSON.stringify(action.payload));
			return {
				...state,
				clientes: action.payload
			};
		case typesChart.GET_CASINOS:
			localStorage.setItem('casinos', JSON.stringify(action.payload));
			return {
				...state,
				casinos: action.payload
			};
		case typesChart.GET_CODIGOMEDICION:
			localStorage.setItem('codigosMedicion', JSON.stringify(action.payload));
			return {
				...state,
				codigosMedicion: action.payload
			};
		case typesChart.GET_BYI:
			localStorage.setItem('byI', JSON.stringify(action.payload));
			return {
				...state,
				byI: action.payload
			};
		case typesChart.GET_LINEA_NEGOCIO:
			localStorage.setItem('lineaDeNegocio', JSON.stringify(action.payload));
			return {
				...state,
				lineaDeNegocio: action.payload
			};
		case typesChart.GET_PREGUNTA_ATRIBUTO_MODULO:
			localStorage.setItem('moduloPregunta', JSON.stringify(action.payload));
			return {
				...state,
				moduloPregunta: action.payload
			};
		case typesChart.BUSCANDO_API:
			let newBuscandoApi = Object.assign(state.buscandoApi);
			let key = action.payload.key;
			newBuscandoApi[key] = action.payload.value;
			return {
				...state,
				buscandoApi: newBuscandoApi
			};
		

		default:
			// console.log(action.type);
			return state;
	}
};
