
import React from 'react'
import { Link } from "react-router-dom";
import SingleItemLabel from './SingleItemLabel.jsx'
import SingleItemIcon from './SingleItemIcon.jsx'
const SingleItem = ({item}) => {



   return (
          <Link
   
            to={item.path}
            className="ripple"
            titulo={item.name && item.name !== "" ? item.name : "Home"}
         >
         {item.label && <SingleItemLabel label={item.label} />}
         {item.icon && <SingleItemIcon src={item.icon} />}
     <span>{item.name}</span>
   </Link>
   )
}
export default SingleItem
