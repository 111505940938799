

import React, { useReducer } from 'react';
import usuarioReducer from './usuarioReducer';
import usuarioContext from './usuarioContext';
import axios from 'axios';
import { typesUser } from '../../types/types';
import Swal from 'sweetalert2';

const UsuarioState = (props) => {
   const config = require('../../configuration');
	const API_BASE_URL = config.API_BASE_URL;
	const initialState = 
   {
      users:[],
      alertUser:{
         mensaje:'',
         tipoMensaje:'',
         isActive:false
      }
   };


   const [ state, dispatch ] = useReducer(usuarioReducer, initialState);


   const LimpiarUsers = (users) => {

      users.forEach((element,i) => {
         
         

         Object.keys(element).forEach((key,i) =>{
      
           if(element[key] === null){
               element[key] = ''
        }
         })
      });

   } 
   const createUser = async (user) => {
      
      const response = await axios.post(API_BASE_URL+'/createuser', user)
         .then(({data}) => {
            if ( parseInt(data.status) === 200 ) {
               // Swal.fire('Buena trabajo!', data.msg, 'success');
               return true;
            } else {
               Swal.fire('Oops...', data.msg, 'error');
               return false;
            }
         }).catch((error) => {
            console.log(error)
         });
      return response;
   }
   
   const getUsers = async () => {
      await axios.get(API_BASE_URL+'/tablauser')
      .then(
         (response)=> {
            console.log(response);
            LimpiarUsers(response.data)
            dispatch({
               type:typesUser.GET_USERS,
               payload:response.data
            });
      }).catch((error) => {
         console.log(error);
      })

   }
   const updateUser = async(user) => {
      console.log("Me modifico",user);
      await axios.put(API_BASE_URL+`/Modificar/${user.id}`,user)
      .then((response) =>{
         console.log(response);
         dispatch({
            type:typesUser.ALERT_EDIT_REGISTER,
            payload:{
               mensaje:'Modificado Correctamente',
               tipoMensaje:'success',
               isActive:true
            }
         })

         setTimeout(() => {
            dispatch({
               type:typesUser.DELETE_ALERT
            })
         }, 4000);
        

      }).catch((error) => {
         console.log(error);
         dispatch({
            type:typesUser.ALERT_EDIT_REGISTER,
            payload:{
               mensaje:'Problema al editar',
               tipoMensaje:'error',
               isActive:true
            }
         })
         setTimeout(() => {
            dispatch({
               type:typesUser.DELETE_ALERT
            })
         }, 4000);

         
      })
   }
   
   const deleteUser = async(user) => {
      let response = await axios.post(API_BASE_URL+`/deleteUser`, user)
         .then(({data}) =>{
            if ( parseInt(data.status) === 200 ) {
               Swal.fire('Buena trabajo!', data.msg, 'success');
               return true;
            } else {
               Swal.fire('Oops...', data.msg, 'error');
               return false;
            }
         }).catch((error) => {
            console.log(error);
         });
      return response;
   }

  return (
   <usuarioContext.Provider
      value={{
         users:state.users,
         alertUser:state.alertUser,
         createUser,
         getUsers,
         updateUser,
         deleteUser,
      }}
   >
      	{props.children}
   </usuarioContext.Provider>
   );
};

export default UsuarioState;
