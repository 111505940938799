import { useReducer } from 'react';
import React from 'react';
import axios from 'axios';
import chartContext from './chartContext';
import chartReducer from './chartReducer';
import { typesChart } from '../../types/types';
import useFilterParams from '../../hooks/useFilterParams';

const ChartState = (props) => {
	const config = require('../../configuration');
	const API_BASE_URL = config.API_BASE_URL;
	const params = {
		preguntas: [ 'P3' ],
		filtros: [
			{
				sexo: 'masculino'
			},
			{
				edad: '23'
			},
			{
				periodo: '2020'
			}
		],
		codmedicion: 202108
	};
	const initialState = {
		ccSQL: localStorage.getItem('ccSQL'),
		mediciones: [],
		contenidoEvolutivoResumen: [],
		chartEvolutivo: [],
		chartPreguntaEvolutivo: [],
		dataTable: [],
		rankings: [],
		platos: [],
		all_platos: [],
		lobs: JSON.parse(localStorage.getItem('lobs')) || [],
		clientes: JSON.parse(localStorage.getItem('clientes')) || [],
		casinos: JSON.parse(localStorage.getItem('casinos')) || [],
		codigosMedicion: JSON.parse(localStorage.getItem('codigosMedicion')) || [],
		byI: JSON.parse(localStorage.getItem('byI')) || [],
		lineaDeNegocio: JSON.parse(localStorage.getItem('lineaDeNegocio')) || [],
		filcalYears: JSON.parse(localStorage.getItem('filcalYears')) || [],
		moduloPregunta: [],
		buscandoApi: {
			chartsResumen: false,
			chartPreguntasEvolutivos:false,
			chartEvolutivo: false,
			tableEvolutivo: false,
			tableRanking: false
		},
		params: params
	};

	const [ state, dispatch ] = useReducer(chartReducer, initialState);

	const statusApi = (key, isActive) => {
		dispatch({
			type: typesChart.BUSCANDO_API,
			payload: { key: key, value: isActive }
		});
	};

	const getUltimasMediciones = async (filters, agno_fiscal) => {
		let resultado = useFilterParams(filters, 'DataTable');
		await axios
			.post(API_BASE_URL + '/api/get-ultimas-mediciones', {
				preguntas: [ 'P2_6', 'P2_7', 'P2_8', 'P3_10', 'P4_12', 'P5_14', 'P5_15', 'P5_16' ],
				agno_fiscal: `${agno_fiscal}`,
				filtros: resultado
			})
			.then((response) => {
				// console.log(response, 'Ultimas mediciones');
				dispatch({
					type: typesChart.GET_ULTIMAS_MEDICIONES,
					payload: response.data
				});
			})
			.catch((error) => {});
	};
	const getFullData = async (filters, agno_fiscal, codMedicion = 0) => {
		statusApi('tableEvolutivo', true);
		dispatch({
			type: typesChart.GET_DATA_TABLE,
			payload: []
		});
		let resultado = useFilterParams(filters, 'DataTable');
		//* Llamada a la api
		await axios
			.post(API_BASE_URL + '/api/get-data-table', {
				ccSQL: initialState.ccSQL,
				codmedicion: codMedicion,
				agno_fiscal: `${agno_fiscal}`,
				filtros: resultado
			})
			.then((response) => {
				// console.log(response, 'data');
				dispatch({
					type: typesChart.GET_DATA_TABLE,
					payload: response.data
				});
				dispatch({
					type: typesChart.BUSCANDO_API,
					payload: { key: 'tableEvolutivo', value: false }
				});
			})
			.catch((error) => {
				// console.log(error);
				statusApi('tableEvolutivo', false);
			});
	};
	const getPreguntasEvolutivo = async (filters, preguntas, agno_fiscal) => {
		let resultado = useFilterParams(filters,'EvolutivoPregunta');
		statusApi('chartPreguntasEvolutivos', true);
		dispatch({
			type: typesChart.GET_CHART_PREGUNTA_EVOLUTIVO,
			payload: []
		});
		await axios
			.post(API_BASE_URL + '/api/get_data-questions', {
				ccSQL: initialState.ccSQL,
				preguntas: preguntas,
				agno_fiscal: `${agno_fiscal}`,
				filtros: resultado
			})
			.then((response) => {
				// console.log(response, 'PreguntasEvolutivo');
				statusApi('chartPreguntasEvolutivos', false);
				dispatch({
					type: typesChart.GET_CHART_PREGUNTA_EVOLUTIVO,
					payload: response.data
				});
			})
			.catch((error) => {
				statusApi('chartPreguntasEvolutivos', false);
				// console.log(error);
			});
	};
	const getEvolutivoResumen = async (filters, agno_fiscal) => {
		let resultado2 = useFilterParams(filters, 'EvolutivoResumen');
		// console.log(filters, resultado2, agno_fiscal);
		statusApi('chartsResumen', true);
		dispatch({
			type: typesChart.GET_CONTENIDO_EVOLUTIVO_RESUMEN,
			payload: []
		});
		await axios
			.post(API_BASE_URL + '/api/get_data-questions', {
				ccSQL: initialState.ccSQL,
				preguntas: [ 'P2_6', 'P2_7', 'P2_8', 'P3_10', 'P4_12', 'P5_14', 'P5_15', 'P5_16' ],
				agno_fiscal: `${agno_fiscal}`,
				filtros: resultado2
			})
			.then((response) => {
				// console.log(response, 'dashboard');
				dispatch({
					type: typesChart.GET_CONTENIDO_EVOLUTIVO_RESUMEN,
					payload: response.data
				});
				statusApi('chartsResumen', false);
			})
			.catch((error) => {
				// console.log(error);
				dispatch({
					type: typesChart.GET_CHART,
					payload: []
				});
				statusApi('chartsResumen', false);
			});
	};
	const getEvolutivo = async (filtro, pregunta, agno_fiscal) => {
		let resultado = useFilterParams(filtro,'EvolutivoPregunta');
		// console.log(resultado, 'evolutivo', agno_fiscal);
		statusApi('chartEvolutivo', true);
		dispatch({
			type: typesChart.GET_CHART_EVOLUTIVO,
			payload: []
		});
		//* Llamada a la api
		await axios
			.post(API_BASE_URL + '/api/get_data-questions', {
				ccSQL: initialState.ccSQL,
				preguntas: [ pregunta ],
				agno_fiscal: `${agno_fiscal}`,
				filtros: resultado
			})
			.then((response) => {
				// console.log(response,'ChartEvolutivo');
				dispatch({
					type: typesChart.GET_CHART_EVOLUTIVO,
					payload: response.data
				});
				statusApi('chartEvolutivo', false);
			})
			.catch((error) => {
				// console.log(error);
				dispatch({
					type: typesChart.GET_CHART,
					payload: []
				});
				statusApi('chartEvolutivo', false);
			});
	};
	const getRanking = async (filtro, agno_fiscal, pregunta) => {
		// console.log('ranking');
		dispatch({
			type: typesChart.GET_RANKING,
			payload: []
		});
		let resultado = useFilterParams(filtro,'Ranking');
		statusApi('tableRanking', true);
		await axios
			.post(API_BASE_URL + '/api/get-data-ranking', {
				ccSQL: initialState.ccSQL,
				preguntas: [ `${pregunta}` ],
				agno_fiscal: `${agno_fiscal}`,
				filtros: resultado
			})
			.then((result) => {
				// console.log(result.data, 'ranking');
				statusApi('tableRanking', false);
				dispatch({
					type: typesChart.GET_RANKING,
					payload: result.data
				});
			})
			.catch((error) => {
				// console.log(error);
				statusApi('tableRanking', false);
			});
	};
	const getFilcalYears = async () => {
		//* Llamada a la api
		await axios
			.get(API_BASE_URL + '/api/get-agnoFiscal')
			.then((response) => {
				// console.log(response, 'Año fiscal');
				dispatch({
					type: typesChart.GET_FILCAL_YEARS,
					payload: response.data
				});
			})
			.catch((error) => {
				// console.log(error);
				dispatch({
					type: typesChart.GET_FILCAL_YEARS,
					payload: []
				});
			});
	};
	const getLobs = async (filters) => {
		let resultado = useFilterParams(filters, 'lobs');
		await axios
			.post(API_BASE_URL + '/api/get-lob', {
				ccSQL: initialState.ccSQL,
				agno_fiscal: `${resultado[0].valor}`,
				filtros: []
			})
			.then((response) => {
				// console.log(response, 'lobs');
				dispatch({
					type: typesChart.GET_LOBS,
					payload: response.data
				});
			})
			.catch((error) => {
				// console.log(error);
				dispatch({
					type: typesChart.GET_LOBS,
					payload: []
				});
			});
	};
	const getAllClient = async (filters, agnofiscal) => {
		let resultado = useFilterParams(filters, 'clientes');
		//* Llamada a la api
		await axios
			.post(API_BASE_URL + `/api/get-cliente`, {
				ccSQL: initialState.ccSQL,
				agno_fiscal: `${agnofiscal}`,
				filtros: resultado
			})
			.then((response) => {
				// console.log(response, 'clientes');
				dispatch({
					type: typesChart.GET_ALL_CLIENT,
					payload: response.data
				});
			})
			.catch((error) => {
				// console.log(error);
				dispatch({
					type: typesChart.GET_ALL_CLIENT,
					payload: []
				});
			});
	};
	const getCasino = async (filter, agno_fiscal) => {
		let resultado = useFilterParams(filter, 'casinos');
		// console.log(resultado, agno_fiscal, 'casinos');
		//* Llamada a la api
		await axios
			.post(API_BASE_URL + `/api/get-casino`, {
				ccSQL: initialState.ccSQL,
				agno_fiscal: `${agno_fiscal}`,
				filtros: resultado
			})
			.then((response) => {
				// console.log(response, 'casinos');
				dispatch({
					type: typesChart.GET_CASINOS,
					payload: response.data
				});
			})
			.catch((error) => {
				// console.log(error);
				dispatch({
					type: typesChart.GET_CASINOS,
					payload: []
				});
			});
	};
	const getCodigoMedion = async (filter, agno_fiscal) => {
		let resultado = useFilterParams(filter, 'codMedicion');
		// console.log(resultado, 'codMedicion');
		//* Llamada a la api
		await axios
			.post(API_BASE_URL + `/api/get-medicion`, {
				ccSQL: initialState.ccSQL,
				agno_fiscal: `${agno_fiscal}`,
				filtros: resultado
			})
			.then((response) => {
				// console.log(response, 'codMedicion');

				dispatch({
					type: typesChart.GET_CODIGOMEDICION,
					payload: response.data
				});
			})
			.catch((error) => {
				// console.log(error);
				dispatch({
					type: typesChart.GET_CODIGOMEDICION,
					payload: []
				});
			});
	};
	const getBYI = async (filters, agno_fiscal) => {
		let resultado = useFilterParams(filters, 'BYL');
		await axios
			.post(API_BASE_URL + `/api/get-B&I`, {
				ccSQL: initialState.ccSQL,
				agno_fiscal: `${agno_fiscal}`,
				filtros: resultado
			})
			.then((response) => {
				// console.log(response, 'B&I');
				dispatch({
					type: typesChart.GET_BYI,
					payload: response.data
				});
			})
			.catch((error) => {
				// console.log(error);
				dispatch({
					type: typesChart.GET_BYI,
					payload: []
				});
			});
	};
	const getLineaDeNegocio = async (filters, agno_fiscal) => {
		let resultado = useFilterParams(filters, 'lineadenegocio');
		await axios
			.post(API_BASE_URL + `/api/get-lineaNegocio`, {
				ccSQL: initialState.ccSQL,
				agno_fiscal: `${agno_fiscal}`,
				filtros: resultado
			})
			.then((response) => {
				// console.log(response, 'lineaNegocio');
				dispatch({
					type: typesChart.GET_LINEA_NEGOCIO,
					payload: response.data
				});
			})
			.catch((error) => {
				// console.log(error);
				dispatch({
					type: typesChart.GET_LINEA_NEGOCIO,
					payload: []
				});
			});
	};
	const getModuloPregunto = async (pregunta) => {
		await axios
			.post(API_BASE_URL + `/api/get-modulo-pregunta`, {
				ccSQL: initialState.ccSQL,
				pregunta: [ `${pregunta}` ]
			})
			.then((response) => {
				// console.log(response, 'moduloPregunta');
				dispatch({
					type: typesChart.GET_PREGUNTA_ATRIBUTO_MODULO,
					payload: response.data
				});
			})
			.catch((error) => {
				// console.log(error);
				dispatch({
					type: typesChart.GET_PREGUNTA_ATRIBUTO_MODULO,
					payload: []
				});
			});
	};
	const getPlantosFavoritos = async (agno_fiscal, filters) => {
		let resultado = useFilterParams(filters, '20PlatosFavoritos');
		axios
			.post(API_BASE_URL + `/api/get-top20-favorite-dishes`, {
				ccSQL: initialState.ccSQL,
				agno_fiscal: `${agno_fiscal}`,
				filtros: resultado
			})
			.then((response) => {
				// console.log(response, 'Platos');
				dispatch({
					type: typesChart.GET_PLATOS_RANKING,
					payload: response.data
				});
			})
			.catch((error) => {
				// console.log(error);
			});
	};

	const getAllPlantos = async (agno_fiscal, filters) => {
		let resultado = useFilterParams(filters, '20PlatosFavoritos');
		axios
			.post(API_BASE_URL + `/api/get-all-dishes`, {
				ccSQL: initialState.ccSQL,
				agno_fiscal: `${agno_fiscal}`,
				filtros: resultado
			})
			.then((response) => {
				// console.log(response, 'All Platos');
				dispatch({
					type: typesChart.GET_ALL_PLATOS_RANKING,
					payload: response.data
				});
			})
			.catch((error) => {
				// console.log(error);
			});
	};

	const getLobsAll = async () => {
		// let resultado = useFilterParams(filters, 'lobs');
		const dataLob = await axios
			.post(API_BASE_URL + '/api/get-lob-all', {
				filtros: []
			})
			.then(({data}) => data)
			.catch((error) => {
				// console.log(error);
				// dispatch({
				// 	type: typesChart.GET_LOBS,
				// 	payload: []
				// });
				return [];
			});
		return dataLob;
	};

	const getLineaDeNegocioUser = async (filters) => {
		const resultado = useFilterParams(filters, 'filtrosMantenedor');
		const dataNegocio = await axios
			.post(API_BASE_URL + `/api/get-lineaNegocio-all`, {
				filtros: resultado
			})
			.then(({data}) => data)
			.catch((error) => {
				// console.log(error);
				// dispatch({
				// 	type: typesChart.GET_LINEA_NEGOCIO,
				// 	payload: []
				// });
			});
		return dataNegocio;
	};

	const getBYLUser = async (filters) => {
		let resultado = useFilterParams(filters, 'filtrosMantenedor');
		console.log(resultado, 'filtrosMantenedor')
		const dataByL = await axios
			.post(API_BASE_URL + `/api/get-B&I-all`, {
				filtros: resultado
			})
			.then(({data}) => data)
			.catch((error) => {
				// console.log(error);
				return [];
			});
		return dataByL;
	};

	const getAllClientUser = async (filters) => {
		let resultado = useFilterParams(filters, 'filtrosMantenedor');
		//* Llamada a la api
		const dataClient = await axios
			.post(API_BASE_URL + `/api/get-cliente-all`, {
				filtros: resultado
			})
			.then(({data}) => data)
			.catch((error) => {
				// console.log(error);
				return []
			});
		return dataClient;
	};

	const getCasinoAll = async (filters) => {
		let resultado = useFilterParams(filters, 'filtrosMantenedor');
		//* Llamada a la api
		const dataCasino = await axios
			.post(API_BASE_URL + `/api/get-casino-all`, {
				filtros: resultado
			})
			.then(({data}) => data)
			.catch((error) => {
				// console.log(error);
				return []
			});
		return dataCasino;
	};

	return (
		<chartContext.Provider
			value={{
				contenidoEvolutivoResumen: state.contenidoEvolutivoResumen,
				chartEvolutivo: state.chartEvolutivo,
				chartPreguntaEvolutivo: state.chartPreguntaEvolutivo,
				rankings: state.rankings,
				clientes: state.clientes,
				dataTable: state.dataTable,
				lobs: state.lobs,
				casinos: state.casinos,
				codigosMedicion: state.codigosMedicion,
				byI: state.byI,
				lineaDeNegocio: state.lineaDeNegocio,
				buscandoApi: state.buscandoApi,
				filcalYears: state.filcalYears,
				moduloPregunta: state.moduloPregunta,
				platos: state.platos,
				mediciones: state.mediciones,
				all_platos: state.all_platos,
				getEvolutivoResumen,
				getEvolutivo,
				getRanking,
				getAllClient,
				getLobs,
				getCasino,
				getCodigoMedion,
				getBYI,
				getLineaDeNegocio,
				getFullData,
				getFilcalYears,
				getModuloPregunto,
				getPlantosFavoritos,
				getAllPlantos,
				getUltimasMediciones,
				getPreguntasEvolutivo,
				getLobsAll,
				getLineaDeNegocioUser,
				getBYLUser,
				getAllClientUser,
				getCasinoAll,
			}}
		>
			{props.children}
		</chartContext.Provider>
	);
};
export default ChartState;
