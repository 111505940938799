import Lock from "./Lock";
import Login from "./Login";
import Recover from "./Recover";
// import Signup from "./Signup";
import User from "./User";
import Layout from "./Layout";

export default {
  Lock,
  Login,
  Recover,
  // Signup,
  User,
  Layout,
};
