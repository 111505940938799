export const typeGlobalConfig = {
  addAllSidebar: "addAllSidebar",

  addNewAgrupacionToSidebar: "addNewAgrupacionToSidebar",
  updateOneAgrupacionOnSidebar: "updateOneAgrupacionOnSidebar",
  removeOneAgrupacionOnSidebar: "removeOneAgrupacionOnSidebar",
  

  addNewMenuToSidebar: "addNewMenuToSidebar",  
  updateOneMenuOnSidebar: "updateOneMenuOnSidebar",
  removeOneMenuOnSidebar: "removeOneMenuOnSidebar",
  
  setPageTitle: "setPageTitle",
  add: "add",
  update: "update",
  setConfigHeader: "setConfigHeader",
};
