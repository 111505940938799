import { types } from "../../types/types";

// const state = {
//     name: 'Francisco',
//     logged:true
// }

export const authReducer = (state = {}, action) => {
  switch (action.type) {
    case types.login:
      return {
        ...action.payload,
        logged: true,
      };
    case types.logout:
      return {
        logged: false,
      };
    case types.setPages:
      return {
        ...state,
        paginas: action.payload,
        ok: true,
      };
    
    case types.addNewRoute:      
      return {
        ...state,
        paginas: [...state.paginas, action.payload],
        ok:true,
      }
    case types.UpdateRoute:      
      return {
        ...state,
        paginas: state.paginas.map(
          pagina=> (pagina.ID === action.payload.ID)
          ? action.payload
          : pagina
        ),
        ok:true,
      }
    case types.DeleteRoute: 
    
    console.log('action.payload', action.payload)     
    console.log('state.paginas', state.paginas)  
      return {
        ...state,
        paginas: state.paginas.filter((pagina) => pagina.ID !== action.payload),
        ok:true,
      }
    
    default:
      return state;
  }
};
