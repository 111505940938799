import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { API_BASE_URL } from "../../configuration";

const correoRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
const Recover = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async ( e ) => {
    e.preventDefault();
    const emailProcess = email.trim().toLowerCase();
    if(!emailProcess) {
      return Swal.fire('Error!', 'Debe ingresar su email.', 'error');
    }
    
    if(!correoRegex.test(emailProcess)) {
      return Swal.fire('Error!', 'Su correo no tiene un formato válido.', 'error');
    }
    
    setIsLoading(true);
    const apiUrl = `${API_BASE_URL}/reset-password/${email}`;
    try {
      const response = await axios({ url: apiUrl, method: 'GET'});
      console.log(response); 
      setIsLoading(false);
    } catch (error) {
      console.log(error.response.data);
      setIsLoading(false);
    }
  }

  return (
    <div className="container-full">
      <div className="container container-xs">
        <div className="text-center">
          <img
            className="mv-lg img-fluid thumb128"
            src="img/logo-cadem.svg"
            alt="Logo Cadem"
          />
        </div>
        <form
          className="cardbox b0 form-validate"
          name="formLogin"
          onSubmit={handleSubmit}
        >
          <div className="cardbox-heading">
            <div className="cardbox-title text-center">
              ¿Has olvidado tu contraseña?
            </div>
            <p className="text-center mt-1">
              Puede restablecer su contraseña aquí.
            </p>
          </div>
          <div className="cardbox-body">
            <div className="mda-form-group float-label mda-input-group">
              <div className="mda-form-control">
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={email}
                  disabled={isLoading}
                  onChange={({ target }) => setEmail(target.value)}
                />
                <div className="mda-form-control-line" />
              </div>
              <span className="mda-input-group-addon">
                <em className="ion-ios-email-outline icon-lg" />
              </span>
            </div>
          </div>
          <div className="cardbox-footer d-flex justify-content-between align-items-center">
            <Link to="/login" className="btn btn-purple text-decoration-none">
              Volver a iniciar sesión
            </Link>
            <button
              className="btn btn-purple"
              type="submit"
              disabled={isLoading}
            >
              Restablecer contraseña.
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Recover;
