import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../configuration";

const Lock = ({ history }) => {
  const { data } = useParams();
  localStorage.setItem('userToken', data)
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const passwordProcess = password.trim();
    if(!passwordProcess){
      return Swal.fire('Error!', 'Debe ingresar su contraseña.', 'error');
    } 

    if(passwordProcess.length > 18 || passwordProcess.length < 4){
      return Swal.fire('Error!', 'Su contraseña debe tener 18 caracteres de maximo y 4 de minimo', 'error');
    }

    setIsLoading(true);
    await axios.put(API_BASE_URL+'/change-password', { password })
    .then(({data: responseData}) => {
      setIsLoading(false);
      Swal.fire('Exito!', responseData.message, 'success') ;
      localStorage.clear();
      history.replace('/');
    })
    .catch(({response: { data }}) => {
      setIsLoading(false);
      return Swal.fire('Error!',  data.message, 'error') ;
    });
  }

  useEffect(() => {
    console.log('token: ', data);
    async function myTokenIsExpired() {
      await axios.get(`${API_BASE_URL}/my-token-is-expired`)
      .catch(({response: { data: responseError } }) => {
        if(responseError.reload){
          history.go(0);
        }else{
          Swal.fire('Error!', responseError.message, 'error') ;
          history.replace('/login');
        }
      })
    }
    
    // 1. validar que es token este en la url. 
    myTokenIsExpired();
  }, [])

  return (
    <div className="container-full">
      <div className="container container-xs">
        <div className="text-center">
          <img
            className="mv-lg img-fluid thumb128"
            src="img/logo-cadem.svg"
            alt="Logo Cadem"
          />
        </div>
        <form 
          className="cardbox b0 form-validate" 
          name="formLogin"
          onSubmit={handleSubmit}
        >
          <div className="cardbox-offset pb0">
            <div className="cardbox-offset-item text-right invisible">
              <div className="btn btn-success btn-circle btn-lg">
                <em className="ion-checkmark-round" />
              </div>
            </div>
          </div>
          <div className="cardbox-heading">
            <div className="cardbox-title text-center">Crear nueva contraseña.</div>
          </div>
          <div className="cardbox-body">
            <div className="mda-form-group float-label mda-input-group">
              <div className="mda-form-control">
                <input 
                  type="password" 
                  name="password" 
                  placeholder="Contraseña"
                  value={password}
                  disabled={isLoading}
                  onChange={({target}) => setPassword(target.value)}
                />
                <div className="mda-form-control-line" />
              </div>
              <span className="mda-input-group-addon">
                <em className="ion-ios-locked-outline icon-lg" />
              </span>
            </div>
          </div>
          <button className="btn btn-purple" type="submit" disabled={isLoading}>
            Actualizar
          </button>
        </form>
      </div>
    </div>
  );
};

export default Lock;
