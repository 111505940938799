

//export const SIZE_FILE =  30000000;
//export const API_BASE_URL = "http://localhost:5000"; // Desarrollo
//export const API_BASE_URL = "https://backend-web-visualizador-vffgqz5kjq-ue.a.run.app"; // Produccion Cloud Run 

export const SIZE_FILE  = 30000000; 
// export const API_BASE_URL  = process.env.NODE_ENV === 'production'
//                                ? 'http://localhost:5000'
//                                : 'http://localhost:5000';
export const API_BASE_URL  = process.env.NODE_ENV === 'production'
                               ? 'https://be-mallplaza-2023-znedv7hw4a-ue.a.run.app'
                               : 'https://be-mallplaza-2023-znedv7hw4a-ue.a.run.app';
                               
                              //  https://bl-estudios-aramark-vffgqz5kjq-ue.a.run.app
