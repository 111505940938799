import React, {useContext, useEffect, useState, Suspense, lazy } from 'react';
import {Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { AuthContext } from './context/auth/AuthContext';
import ChartArea  from './components/Charts/ChartArea';

import PageLoader from './components/Common/PageLoader';

/* eslint-disable-next-line */
import Core from './components/Core/Core';
import componentMap from "./ComponentMap";

const config = require('./configuration');
const API_BASE_URL = config.API_BASE_URL;
const PageNotFound = lazy(() => import('./views/Errors/PageNotFound'));
const DisponibilidadLocales = lazy(() => import('./views/viewMallPlaza/DisponibilidadLocales'));
const FundacionamientoYoperacion = lazy(() => import('./views/viewMallPlaza/FundacionamientoYoperacion'));
const MarketingPromocion = lazy(() => import('./views/viewMallPlaza/MarketingPromocion'));
const ProcesoContratacion = lazy(() => import('./views/viewMallPlaza/ProcesoContratacion'));
const ProcesoDeHabilitacionLocales = lazy(() => import('./views/viewMallPlaza/ProcesoDeHabilitacionLocales'));
const ProcesoFacturacion = lazy(() => import('./views/viewMallPlaza/ProcesoFacturacion'));
const RelacionComercial = lazy(() => import('./views/viewMallPlaza/RelacionComercial'));
const TasaProblemas = lazy(() => import('./views/viewMallPlaza/TasaProblemas'));
const Perfil = lazy(() => import('./views/viewMallPlaza/Perfil'));



const ROL = localStorage.getItem("rol");

export const DashboardRoutes = ({location}) => {
    const { user }  = useContext(AuthContext);
    const [state, setstate] = useState({})
    const paginas = user.paginas || [];
    const currentKey = location.pathname.split('/')[1] || '/';
    const timeout = { enter: 500, exit: 500 };
    const animationName = 'rag-fadeIn';

    let routeComponents = []
    paginas.map(({RUTA, COMPONENTE, TIPO}, i) => {
        const Component = componentMap[ COMPONENTE ] || componentMap.default;
        //console.log('TIPO', TIPO);

        TIPO == 'DYNAMIC'
        ? routeComponents.push(<Route path={`${RUTA}/:estudioId`} key={i}> <Component/></Route>)
        : routeComponents.push(<Route path={RUTA} key={i}><Component/></Route>)

        // TIPO == 'DYNAMIC'
        //                 ? routeComponents.push(<Route path={`${RUTA}/:surveyId`} component= {Component} key={i}/>)
        //                 : routeComponents.push(<Route path={RUTA} component={Component} key={i}/>)

    });


    let countRoutes = paginas.length;
    routeComponents.push(<Route path="/PageNotFound" component={PageNotFound} key={countRoutes + 1}/>);
    routeComponents.push(<Route path="/RelacionComercial" component={RelacionComercial} key={countRoutes + 2}/>);
    routeComponents.push(<Route path="/DisponibilidadLocales" component={DisponibilidadLocales} key={countRoutes + 3}/>);
    routeComponents.push(<Route path="/ProcesoDeHabilitacion" component={ProcesoDeHabilitacionLocales} key={countRoutes + 4}/>);
    routeComponents.push(<Route path="/FuncionamientoOperacion" component={FundacionamientoYoperacion} key={countRoutes + 5}/>);
    routeComponents.push(<Route path="/MarketingPromocion" component={MarketingPromocion} key={countRoutes + 6}/>);
    routeComponents.push(<Route path="/ProcesoFacturacion" component={ProcesoFacturacion} key={countRoutes + 7}/>)
    routeComponents.push(<Route path="/ProcesoContratacion" component={ProcesoContratacion} key={countRoutes + 8}/>);
    routeComponents.push(<Route path="/TasaDeProblemas" component={TasaProblemas} key={countRoutes + 9}/>);
    routeComponents.push(<Route path="/Perfil" component={Perfil} key={countRoutes + 10}/>);

    ;
    /*
        routeComponents.push(<Route path="/ranking" component={Ranking} key={paginas.length + 2}/>);
    */
    // EVOLUTIVO

  



    // Comparativo


    // routeComponents.push(<Route path="/mantenedormenuizquierdo" component={MantenedorMenuIzquierdo} key={paginas.length + 7}/>);

    routeComponents.push(<Redirect to="/PageNotFound" key={paginas.length + 3}/>);

    const routeInitial =  [<Redirect to="/login" key={1}/>];


 return (

    <Core>
    <TransitionGroup>
      <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
          <div>
              <Suspense fallback={<PageLoader/>}>
                  <Switch location={location}>
                      {paginas.length === 0 ? routeInitial : routeComponents  }
                  </Switch>
              </Suspense>
          </div>
      </CSSTransition>
    </TransitionGroup>
  </Core>

)
}
