import React, { useEffect, useReducer } from "react";
import { BrowserRouter } from "react-router-dom";
import { unregister } from "./interceptor";

// Vendor dependencies
import "./Vendor";
// App Routes

import "./components/Ripple/Ripple.init.js";

import Routes from "./Routes";

// import { Routes } from './Routes';
import { AuthContext } from "./context/auth/AuthContext";
import { authReducer } from "./context/auth/authReducer";

import { GlobalConfigContext } from "./context/global/GlobalConfigContext";
import { globalConfigReducer } from "./context/global/globalConfigReducer";
import ChartState from "./context/chartData/ChartState";
import UsuarioState from "./context/usuario/UsuarioState";
const init = () => {
  return JSON.parse(localStorage.getItem("user")) || { logged: false };
};

const initGlobalConfig = () => {
  return (
    JSON.parse(localStorage.getItem("globalConfig")) || {
      configSidebar: [],
      pageTitle: "Home",
      configHeader: {
        pageTitle: "",
      },
      ok: false,
    }
  );
};

const App = () => {
  const [user, dispatch] = useReducer(authReducer, {}, init);
  const [globalConfig, dispatchGlobalConfig] = useReducer(
    globalConfigReducer,
    {},
    initGlobalConfig
  );

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  useEffect(() => {
    localStorage.setItem("globalConfig", JSON.stringify(globalConfig));
  }, [globalConfig]);

  return (
    <GlobalConfigContext.Provider
      value={{ globalConfig, dispatchGlobalConfig }}
    >
      <AuthContext.Provider value={{ user, dispatch }}>
      <ChartState>
        <UsuarioState>
          <Routes />
        </UsuarioState>
      </ChartState>
      </AuthContext.Provider>
    </GlobalConfigContext.Provider>
    
  );
};

export default App;
