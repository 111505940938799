
import { typesUser } from '../../types/types';


export default (state, action) => {
	switch (action.type) {

		case typesUser.GET_USERS:
		return{
			...state,
			users:action.payload
		}
		case typesUser.ALERT_EDIT_REGISTER:
		return{
			...state,
			alertUser:action.payload,
		
		}
		case typesUser.DELETE_ALERT:
		state.alertUser.isActive = false
		console.log(state.alertUser);
		return{
			...state,
			alertUser:state.alertUser,
		
		}
		
		default:
			console.log(action.type);
			return state;
	}
};
