const useFilterParams = (filtros, key) => {
	// Metodo encargado de filtrar los filtros y entregar solo los que poseen valor para obtener valores de data principal
	switch (key) {
		case 'lobs':
			if (filtros[0].valor !== '') return [ filtros[0] ];
			return [];
		case 'lineadenegocio':
			if (filtros[1].valor !== '') return [ filtros[1] ];
			return [];
		case 'BYL':
			if (filtros[1].valor !== '') return [ filtros[1] ];
			return [];
		case 'clientes':
			if (filtros[1].valor !== '') return [ filtros[1] ];
			return [];
		case 'casinos':
			let resultadoTable1 = filtros.filter((filtro) => {
				if (filtro.columna === 'id_lob' || filtro.columna === 'id_cliente') {
					if (filtro.valor) {
						return filtro;
					}
				}
			});
			return resultadoTable1;
		case 'codMedicion':
			let resultadoTable2 = filtros.filter((filtro) => {
				if (
					filtro.columna === 'id_lob' ||
					filtro.columna === 'id_cliente' ||
					filtro.columna === 'id_casino'
				) {
					if (filtro.valor) {
						return filtro;
					}
				}
			});
			return resultadoTable2;
		case 'Ranking':
		case '20PlatosFavoritos':
		case 'DataTable':
		case 'EvolutivoResumen':
			let resultadoTable3 = filtros.filter((filtro) => {
				if (
					filtro.columna === 'id_lob' ||
					filtro.columna === 'id_cliente' ||
					filtro.columna === 'id_casino' ||
					filtro.columna === 'codmedicion' ||
					filtro.columna === 'P1_1' ||
					filtro.columna === 'P1_2'
				) {
					if (filtro.valor) {
						return filtro;
					}
				}
			});
			return resultadoTable3;
		case 'EvolutivoPregunta':
				let resultadoTable4 = filtros.filter((filtro) => {
					if (
						filtro.columna === 'id_lob' ||
						filtro.columna === 'id_cliente' ||
						filtro.columna === 'id_casino' ||
						filtro.columna === 'codmedicion' ||
						filtro.columna === 'P1_1' ||
						filtro.columna === 'P1_2'||
						filtro.columna === 'P1_3'||
						filtro.columna === 'P1_4'||
						filtro.columna === 'P1_5'
					) {
						if (filtro.valor) {
							return filtro;
						}
					}
				});
				return resultadoTable4;
		case 'filtrosMantenedor':
			let jsonReturn = [];
			if ( filtros.length > 0 ) {
				// console.log("Entro...")
				const { key } = filtros[0];
				let valores = null
				filtros.map(({value}) => {
					if ( filtros.length > 1 ) {
						valores = ( valores ) ? `${valores},${value.toString()}` : value.toString();
					} else {
						valores = value.toString();
					}
				});
				jsonReturn = JSON.stringify([{ columna : key, valor : valores }]);
			}
			// console.log(jsonReturn)
			return JSON.parse(jsonReturn);
		default:
			return [];
	}
};

export default useFilterParams;
