import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

/* loader component for Suspense */
/* eslint-disable-next-line */
import PageLoader from "./components/Common/PageLoader";

/* eslint-disable-next-line */
import Core from "./components/Core/Core";
/* eslint-disable-next-line */
import Bootstrap from "./components/Bootstrap/Bootstrap";
/* eslint-disable-next-line */
import Common from "./components/Common/Common";
/* eslint-disable-next-line */
import Colors from "./components/Colors/Colors";
/* eslint-disable-next-line */
// import FloatButton from "./components/FloatButton/FloatButton";
/* eslint-disable-next-line */
import Utils from "./components/Utils/Utils";

// import { LoginRoutes } from "./LoginRoutes";
import { DashboardRoutes } from "./DashboardRoutes";
import User from "./views/User/User";

// const PageNotFound = lazy(() => import('./views/Errors/PageNotFound'));
// import PageNotFound from './views/Errors/PageNotFound';

const Routes = ({ location }) => {
  return (
    <Router >
      <Switch location={location}>
        <Route exact path="/login" component={User.Login}/> 
        <Route exact path="/recover" component={User.Recover}/> 
        <Route exact path="/lock/:data" component={User.Lock}/> 
        <Route path="/" component={DashboardRoutes} />
      </Switch>
    </Router>
  );
};

export default Routes;
