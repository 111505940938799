// import { types as typeGlobalConfig } from "../types/typeGlobalConfig";
import { typeGlobalConfig } from '../../types/typeGlobalConfig';
const deafultManu = 'Mantenedores';
// export const globalConfigReducer = (state = stateInit, action) => {
export const globalConfigReducer = (state, action) => {

	// console.log('state', state);
	// console.log('action :: ', action);
	//console.log(state);
	switch (action.type) {
		case typeGlobalConfig.addAllSidebar:
			return {
				...state,
				configSidebar: action.payload,
				ok: true
			};

		case typeGlobalConfig.addNewAgrupacionToSidebar:
			return {
				...state,
				configSidebar: [ ...state.configSidebar, action.payload ],
				ok: true
			};
		case typeGlobalConfig.updateOneAgrupacionOnSidebar:
			return {
				...state,
				configSidebar: state.configSidebar.map(
					(agrupacion) =>
						agrupacion.AGRUPACION_ID === action.payload.AGRUPACION_ID
							? action.payload.AGRUPACION
							: agrupacion
				),
				ok: true
			};
		case typeGlobalConfig.removeOneAgrupacionOnSidebar:
			return {
				...state,
				configSidebar: state.configSidebar.filter(
					(agrupacion) => agrupacion.AGRUPACION_ID !== action.payload.AGRUPACION_ID
				),
				ok: true
			};

		case typeGlobalConfig.addNewMenuToSidebar:
			//console.log("nuevo menu :: ", action.payload);
			return {
				...state,
				configSidebar: state.configSidebar.map(
					(menu) =>
						menu.AGRUPACION_ID === action.payload.AGRUPACION_ID
							? {
									...menu,
									submenu: [ ...menu.submenu, action.payload.MENU ]
								}
							: menu
				),
				ok: true
			};
		case typeGlobalConfig.updateOneMenuOnSidebar:
			return {
				...state,
				configSidebar: state.configSidebar.map(
					(menu) =>
						menu.AGRUPACION_ID === action.payload.AGRUPACION_ID
							? {
									...menu,
									submenu: menu.submenu.map(
										(menuestudio) =>
											menuestudio.ESTUDIO_ID === action.payload.MENU.ESTUDIO_ID
												? action.payload.MENU
												: menuestudio
									)
								}
							: menu
				),
				ok: true
			};
		case typeGlobalConfig.removeOneMenuOnSidebar:
			return {
				...state,
				configSidebar: state.configSidebar.map(
					(menu) =>
						menu.AGRUPACION_ID === action.payload.AGRUPACION_ID
							? {
									...menu,
									submenu: menu.submenu.filter(
										(menuestudio) => menuestudio.ESTUDIO_ID !== action.payload.ESTUDIO_ID
									)
								}
							: menu
				),
				ok: true
			};

		case typeGlobalConfig.setPageTitle:
			return {
				...state,
				// configHeader: action.payload,
				pageTitle: action.payload,
				ok: true
			};
		case typeGlobalConfig.setConfigHeader:
			return {
				...state,
				configHeader: {
					...state.configHeader,
					pageTitle: action.payload
				},
				ok: true
			};

		default:
			return state;
	}
};
